.mini-button {
    line-height: 2em;
    font-size: 2.5em;
    width: 2em;
    height: 2em;
    border-radius: 2.5em;
    position: fixed;
    bottom: 1em;
    z-index: 1;
    text-align: center;
    color: white;
    animation: slideFromBottom .5s;
}

.mini-button-kill {
    animation: slideToRight .5s forwards;
}

.mini-button:hover {
    filter: brightness(1.1);
    transform: translateY(5px);
    box-shadow: none;
    cursor: pointer;
}

.mini-button-add {
    right: 1em;
    background-color: cornflowerblue;
    box-shadow: rgb(80, 119, 190) 0 5px 0 0;
}

.mini-button-delete {
    left: 1em;
    background-color: red;
    box-shadow: rgb(140, 17, 17) 0 5px 0 0;

}
