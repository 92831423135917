.SliderContent {
    overflow-y: auto;
}

.SliderContent>ul {
    padding: 0 5%;

    display: flex;
    flex-wrap: wrap;
}

.SliderContent>ul>li {
    list-style: none;
    display: inline-block;
    
    width: 50%;
    padding: 0px 10px;
}

.SliderMenu {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.SliderInput {
    margin-bottom: 1em;
}

.SliderMenuEntry {
    margin: .3em auto;
    text-align: center;
    font-size: 2em;
    padding: .5em;
    background-color: white;
    border-radius: .5em;
    box-shadow: 0 0.2em 0.3em 0 gainsboro;
    user-select: none;
}

.unselectable {
    background-color: gray;
}

.SliderMenuEntry:hover[selectable="selectable"],
over,
ected {
    filter: invert(1);
    box-shadow: none;
    font-weight: bold;
}

.SliderMenuEntry li:last-child>.SliderMenuEntry {
    margin-bottom: 1em;
}