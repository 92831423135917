.SliderBranch {
    height: 100%;
    display: flex;
    flex-direction: row;
    /* padding: 0 5em; */
}

.SliderBranchChoice {
    width: 15em;
    height: 15em;
    background-color: white;
    box-shadow: 0 0.5em 0.5em 0 gainsboro;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 2em;
    overflow: hidden;
    margin: auto;
    user-select: none;
    border-radius: 1em;
    margin: auto 1em;
}

.SliderBranchChoice:hover {
    filter: invert(1);
}

.SliderBranchChoice > img {
    width: 50%;
    margin: auto;
    pointer-events: none;
}

.SliderBranchChoice > span {
    display: block;
    text-align: center;
    width: 100%;
    font-size: 2.5em;
    line-height: 1.25em;
    margin-top: .25em;
}