.SliderForm {
    /* min-height: 40%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 5%;
    overflow-y: auto;

    list-style: none;
    width: 100%;
}

.centerDiv {
    text-align: center;
    margin-top: 1em;
}

button {
    text-align: center;
    width: fit-content;
}